import store from "../store";

const headers = (contentType = "application/json") => {
  const headers = {
    api_key: process.env.VUE_APP_API_KEY,
    "Content-Type": contentType,
  }
  const token = store.getters.getUserToken;
  if (token) headers.token = token
  return { headers }
}

export default axios => ({
  // Start header endpoints
  async getCategories() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/categories", headers())
    } catch (e) {
      return e.response
    }
  },
  async getBrands(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/brand/" + (id ?? "all"), headers())
    } catch (e) {
      return e.response
    }
  },
  async getBrandsV2() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/brand/all_v2", headers())
    } catch (e) {
      return e.response
    }
  },
  async getBrandHighlights(brandId) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/brand/highlight/" + brandId, headers())
    } catch (e) {
      return e.response
    }
  },
  async getSingleGroup(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/single_group/" + id, headers())
    } catch (e) {
      return e.response
    }
  },
  async getGroupedBrands(limit = 6) {
    try {
      const obj = {limit}
      const query = []
      for (const o in obj)
        query.push(o + "=" + obj[o])
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/brand_group?" + query.join('&'), headers())
    } catch (e) {
      return e.response
    }
  },
  async getCollections(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/products/product_collection" + (id ? `/${id}` : ''), headers())
    } catch (e) {
      return e.response
    }
  },
  async getPromotions(id) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/promotion" + (id ? `/${id}`: ''), headers())
    } catch (e) {
      return e.response;
    }
  },
  async getMegaMenuBanner(index) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "megamenubanner/" + index, headers())
    } catch (e) {
      return e.response
    }
  },
  async getArticles() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/articles/all", headers())
    } catch (e) {
      return e.response
    }
  },
  async getLizpedia() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/lizpedia", headers())
    } catch (e) {
      return e.response
    }
  },
  async getAdditionalCategory() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/additional_category', headers())
    } catch (e) {
      return e.response
    }
  },
  async getMiniBanner(name) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/minibanner/' + name, headers())
    } catch (e) {
      return e.response
    }
  },
  async getTopMenu() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + 'users/cms/topmenu', headers())
    } catch (e) {
      return e.response;
    }
  },
  // End header endpoints
  async getFaqs() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/faq", headers())
    } catch (e) {
      return e.response
    }
  },
  async getFaqsReseller() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/faq_reseller", headers())
    } catch (e) {
      return e.response
    }
  },
  async getFooterMenu() {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + "users/cms/footer", headers())
    } catch (e) {
      return e.response
    }
  },
  async getMerchantOperationalTime(qbId) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/cms/merchant/op_time?qb_id=${qbId}`, headers())
    } catch (e) {
      return e.response
    }
  },
  async getMerchantClosedStatus(qbId) {
    try {
      return await axios.get(process.env.VUE_APP_API_URL + `users/cms/merchant/closed_store?qb_id=${qbId}`, headers())
    } catch (e) {
      return e.response
    }
  },
})
